/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import "../../../styles/scss/style.scss";
import Text from "../../../components/Text";

const BailoDotContactCard = ({ mapdot = {} }) => {
  const { phoneNr, emailMapdot, website } = mapdot;

  return (
    <>
      {/* Website */}

      <tr className="unread">
        <td>
          <i className="fa-solid fa-address-card"></i>
        </td>
        <td className="td-block">
          <h6 className="mb-1 text-uppercase">
            <Text tid="website" />
          </h6>
          {String(website).trim() === "" ? (
            <p className="m-0">n/a</p>
          ) : (
            <p className="m-0">
              <a href={website} target="_blank" rel="noreferrer">
                <Text tid="linkWebsite" />
              </a>
            </p>
          )}
        </td>

        {/* Teléfono */}

        <td className="td-block">
          <h6 className="mb-1 text-uppercase">
            <Text tid="phoneNr" />
          </h6>
          {String(phoneNr).trim() === "" ? (
            <p className="m-0">n/a </p>
          ) : (
            <p className="m-0">{phoneNr}</p>
          )}
        </td>

        {/* Email */}
        <td className=" td-block">
          <h6 className="mb-1 text-uppercase">
            <Text tid="emailMapdot" />
          </h6>
          {String(emailMapdot).trim() === "" ? (
            <p className="m-0">n/a</p>
          ) : (
            <p className="m-0">{emailMapdot}</p>
          )}
        </td>
      </tr>
    </>
  );
};

BailoDotContactCard.propTypes = {
  auth: PropTypes.object.isRequired,
  lang: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
});

export default connect(mapStateToProps)(BailoDotContactCard);
