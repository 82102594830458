/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import NavLanding from "../../../components/NavLanding";
import Aux from "../../../hoc/_Aux/Aux";
import { Styles } from "../../landing/LandingStyles";
import Prices from "../Prices";

class NavPrices extends Component {
  state = {
    followMapdots: [],
    myAttendMeetings: [],
    loading: true,
  };

  render() {
    return (
      <Aux>
        <Styles>
          <div className="card-body">
            <div className="container">
              <NavLanding />
              <div style={{ paddingTop: "100px" }}>
                <Prices />
              </div>
            </div>
          </div>
        </Styles>
      </Aux>
    );
  }
}

export default NavPrices;
