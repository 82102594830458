/* eslint-disable class-methods-use-this */
import axios from "axios";

class ClientIpService {
  async getIpLocation() {
    const { data } = await axios.get("https://ipapi.co/json/"); // TODO: hide this variable
    return data;
  }
}

const commentService = new ClientIpService();

export default commentService;
