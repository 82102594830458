const eng = {
  aboutMe: "For more info about me",
  profile: "Profile",
  hiAdmin: "Hi Admin",
  username: "Username",
  password: "password",
  login: "Login",
  loginCap: "Login",
  signup: "signup",
  signupCap: "Sign up",
  pricesCap: "Prices",
  loginTitle: "Login to your account",
  signupTitle: "Sign up and start dancing!",
  email: "email",
  contactUs: "Contact",
  privacyPolicy: "Privacy Policy",
  termsOfUse: "Terms of Use",
  copyright: "All Contents Copyright © 2017-2021 Bailo.app",
  followUs: "FOLLOW US: fb, twitter, instagram",
  following: "Following",
  iLike: "I like",
  aboutUs: "About us",
  faq: "FAQ",
  downloadApp: "DOWNLOAD APP",
  withLove: "Made with ❤️ in Barcelona",
  homepage: "home",
  map: "Map",
  myBailo: "My Bailo",
  logout: "logout",
  titleMapdot: "title",
  ownerMapdot: "owner",
  addMapdot: "Add Bailo Dot",
  myBailoDotsFollow: "Bailo Dots I follow",
  myBailoDotsLead: "Bailo Dots I lead",
  myMeetups: "Meetups I go to",
  myAdsLead: "Ads I lead",
  dance: "dance",
  category: "category",
  title: "title",
  description: "description",
  address: "address",
  addressPlus: "additional info",
  postalCode: "postal code",
  city: "city",
  country: "country",
  phoneNr: "phone number",
  emailMapdot: "professional email",
  website: "website",
  day: "day",
  frequency: "frequency",
  dateStart: "date - start",
  dateEnd: "date - end",
  timeStart: "time - start",
  timeEnd: "time - end",
  price: "price",
  typeOf: "type",
  buttonAdd: "Add",
  venue: "milonga",
  practica: "practica",
  tango: "tango",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  once: "once",
  weekly: "weekly",
  fortnightly: "fortnightly",
  monthly: "monthly",
  editMapdot: "edit",
  reviewIt: "Review",
  editBailoDot: "Edit Bailo Dot",
  buttonUpdate: "update",
  buttonDelete: "delete",
  followLink: "follow bailo-dots",
  leadLink: "lead bailo-dots",
  addLink: "add bailo-dots",
  searchFor: "Filter by city",
  searchForMilongaName: "Search by name",
  search: "Search",
  admin: "Admin",
  adminAllBailoDots: "bailo-dots",
  adminPendingBailoDots: "pending bailo-dots",
  adminUsers: "Users",
  customerChat: "Customer Chat",
  danceRole: "dance role",
  statusUser: "status of the user",
  all: "all",
  approved: "approved",
  approve: "Approve",
  reject: "Reject",
  rejected: "rejected",
  onhold: "onhold",
  pending: "pending",
  userProfile: "user profile",
  numberOfLikes: "followers",
  activeSince: "active since",
  musicStyle: "music style",
  additionalInfo: "additionalInfo",
  statusMapdot: "status",
  imageMapdot: "image",
  yes: "yes",
  no: "no",
  realOwner: "Is it your Bailo Dot?",
  useReverseGeo: "use my location",
  locationAnswer: "location of bailo-dot saved",
  userLocation: "Location",
  createdBy: "created by",
  latitude: "latitude",
  longitude: "longitude",
  stars: "stars",
  note: "note",
  date: "date",
  meetups: "meetups",
  addMeetUp: "add a meetup",
  createdMeetups: "created meetups",
  attendMeetups: "attend meetups",
  editMeetUp: "edit meetup",
  userAbout: "About",
  userAboutMe: "About me",
  personalInformation: "Personal Information",
  unfriend: "Unfriend",
  friends: "Friends",
  block: "Block",
  unblock: "Unblock",
  cancelFriendship: "Cancel invitation",
  acceptFriendship: "Accept invitation",
  denyFriendship: "Deny invitation",
  social: "Social",
  shortInfo: "Info",
  reviews: "Reviews",
  noReviews: "No reviews yet",
  addReview: "Add review",
  goGoogle: "How to get there",
  capacity: "Capacity available",
  booking: "Booking",
  dateOfMeeting: "date of meeting",
  hasComments: "comments",
  participants: "participants",
  editProfile: "Edit profile",
  danceRoleLeader: "leader",
  danceRoleFollower: "follower",
  danceRoleBoth: "both",
  new: "New",
  repeat: "Repeat",
  settings: "Settings",
  editPassword: "Edit password",
  landingTitle: "Bailo.app - tango locator map",
  subTitleOne: "With already +1000 milongas worldwide.",
  subTitleTwo:
    "Use the map or access detailed information for each event or milonga.",
  subTitleThree: "You can also register your own milonga.",
  subTitleFour:
    "Navigate, search for accommodation, and reach your final destination easily.",
  landingFeatureOne: "Constantly New Features",
  landingFeatureTwo: "Growing Tango Community",
  landingFeatureThree: "Easy to Use & For Free",
  landingFeatureOneText:
    "Continuous improvement is important to us and we highly appreciate your feedback.",
  landingFeatureTwoText:
    "There is a milonga missing on the map? Add it yourself or let us know and we do it!",
  landingFeatureThreeText:
    "Create o find your milonga in no time at home or during travels.",
  landingStory: "Our Story",
  landingStoryTextOne:
    "Our purpose is to create an international tango community with easy to find milongas worldwide.",
  landingStoryTextTwo:
    "Bailo.app was founded in 2017 under the name Tango Maps as a simple map with our favourite milongas, mainly in Barcelona, New Zealand, Israel, Italy and Córdoba, Argentina. We are both tango dancers and (what a surprise) met dancing the tango in Barcelona.",
  landingStoryTextThree:
    "Up until today, we have received positive feedback from tango dancers around the world who are passionate about tango and love travelling, meeting other tango addicts and enjoy some tango dances. More than a simple list of tango spots, Bailo.app is a constantly evolving online community.",
  landingStoryTextFour:
    "Bailo.app enjoys the tango with its traditions and also supports new movements, such as alternative tango music and gender/dance role swap.",
  landingMarceOne: "Tango addict since 2012",
  landingMarceTwo: "From Argentina",
  landingMarceThree: "Software Analyst & Web Developer",
  landingTashOne: "Tango addict since February 2006",
  landingTashTwo: "From Germany",
  landingTashThree: "Linguist & Web Developer",
  typeLetter: "type a letter",
  noResultsfound: "no results found",
  contactUsText:
    "Do you have any questions or feedback? Feel free to contact us via mail: bailo.app@gmail.com",
  bailoDots: "Bailo Dots",
  follow: "Follow",
  leadBailoDots: "Lead Bailo Dots",
  leadMeetups: "Lead Meetups",
  addBailoDot: "Add Bailo Dot",
  addMeetup: "Add Meetup",
  haveAnAccount: "Already have account?",
  keenTo: "Keen to",
  backTo: "Back to",
  class: "class",
  school: "school",
  teacher: "teacher",
  acceptTUPPOne: "I accept the",
  acceptTUPPTwo: "and",
  help: "Help",
  commonQuestions: "Common Questions",
  bailoYouTube: "Tango Court Métrage",
  bailoYouTubeText:
    "Searching for the best short videos that in some way talk about the tango. The rights of each video belong to each person who uploaded the video. Bailo.app simply promotes the world of the tango.",
  bailoBlog: "Bailo.app Blog",
  bailoBlogText: "Check out our latest Blog Post",
  bailoBlogHomepageTitle: "Homepage of our Blog",
  commonQuestionsTextLanding:
    "How do I add a milonga to the map? What is a Bailo Dot? These and other questions are answered in our common questions section. Let us know if you have any further questions",
  comments: "Comments",
  addComment: "Add Comment",
  userCommentUpdate: "Do you want to update your comment?",
  comment: "comment",
  finalDot: ".",
  notionLink: "Notion",
  sendPush: "Send Push",
  allSentPush: "Push History",
  sureSendPush: "Are you sure you want to send this push?",
  sureApproveIt: "Are you sure you want to approve it?",
  updateComment: "Edit your comment",
  cancelButton: "cancel",
  deleteComment: "Delete your comment",
  cancel: "cancel",
  spotsLeft: "spots left",
  thankYou: "Thank you",
  subscription: "Monthly gift in €",
  aLaGorra: "A la gorra",
  order: "One-time gift in €",
  amount: "amount",
  plan1: "1€ monthly - La Yumba",
  plan2: "2€ monthly - Libertango",
  plan3: "5€ monthly - Farol",
  plan4: "10€ monthly - Poema",
  plan5: "15€ monthly - La cumparsita",
  planCli1: "La Yumba",
  planCli2: "Naranjo en flor",
  planCli3: "La cumparsita",
  paymentTitle:
    "Give as you wish - the value is what the service is worth to you. We're Grateful",
  paymentTextOne:
    "Even though Bailo.app is not a non-profit organization, the website serves the tango community since its creation in 2017. All monetary payments go to build and improve this project. We are a small tech Team and both, Marcelo and Tash, work on Bailo.app in our free time, however, in our heart and mind we are fully dedicated to expanding this website and are also keen to get started with a native app. You may have noticed that on our website we do not display any advertisements, so your individual contributions truly makes a difference in growing the website.",
  paymentTextTwo:
    "Your payment will be used to maintain, improve, and create additional user-friendly features for Bailo.app. This includes covering our server costs and paying for printing flyers, and other operational expenses.",
  paymentTextThree: "Please choose your option for giving",
  paymentTextFour: "One-time gift, you choose the amount",
  paymentTextFive: "Monthly gift, there are 5 plans to choose from",
  paymentTextSix:
    "Choose between a monthly or one-time gift, click on either the PayPal link or the link for a secure credit card payment. Remember that the value is what the service is worth to you.",
  paymentTextSeven: "THANK YOU. GRACIAS. DANKE. MERCI.",
  paymentSecure:
    "Secure payment. Bailo.app guarantees secure payment over the internet using PayPal, PayPal debit or credit car and Bizum tools.",
  paymentMoreInfoPayPal: "For more information visit:",
  empty: "",
  saveChanges: "Save Changes",
  closeButton: "Close",
  refNumber: "Your reference number: ",
  deleteMyUser: "Delete my user",
  publicName: "public name",
  dateOnce: "date",
  termsOfClass: "terms of class",
  classLevel: "level",
  offersOnlineLesson: "online lessons",
  offersPrivateLesson: "private lessons",
  infoAddBailoDot:
    "As soon as we approve your Bailo Dot, you can interconnect it with other Bailo Dots.",
  addSchool: "Link with your school",
  addNoSchool: "Do not link school / unlink",
  noSchoolFound: "no school found",
  linkedSchool: "Linked school",
  noSchoolLinked: "no linked school",
  addressAprox: "approximate location",
  noDescriptionYet: "no description yet",
  connections: "Connections with other Bailo Dots",
  addTeacher: "Link with a teacher",
  addNoTeacher: "Do not link teacher / unlink",
  noTeacherFound: "no teacher found",
  linkedTeacher: "Linked teacher",
  noTeacherLinked: "no linked teacher",
  share: "share",
  sureDelete: "Are you sure you want to delete your Bailo Dot?",
  leaveThisMeetup: "leave this Meetup",
  noNote: "without note",
  commentsGiven: "Comments given",
  updateImg: "Upload image",
  dropImg: "Drop files here",
  imgUpload: "image_upload",
  newMeetupCreated: "has created a new meetup for the",
  chatNotFound: "Chat Not Found",
  registerWithAdminTash: "Please, login with Admin Tash to answer this chat.",
  radio: "radio",
  musician: "musician",
  belongsToRadioStation: "radio station",
  belongsToPodcastPlatform: "podcast platform",
  languageSpokenRadio: "language spoken",
  radioWebsite: "radio website",
  linkWebsite: "link",
  buttonAddFriend: "Add to friends",
  friendRequests: "Friend requests",
  reportInfo: "Report error or confirm details",
  reportErrorDescription: "Error description",
  reportLastConfirmation: "Last confirmation",
  reportNoConfirmation: "Not confirmed yet",
  report: "Report",
  confirm: "Confirm",
  edit: "Edit",
  reportsTitle: "Reports",
  confirmTextOne: "Confirmed 🎉",
  confirmTextTwo: "You can now login via our app and website",
  confirmTextThree: "Download from the Apple Store",
  confirmTextFour: "Download from Google Play",
  confirmTextFive: "Login via our website",
  landingTryForFree: "Try Bailo.app for free!",
  sending: "Sending",
  send: "Send",
  body: "body",
  googleSignup: "Sign up with Google",
  googleLogin: "Login with Google",
  facebookSignup: "Sign up with Facebook",
  facebookLogin: "Login with Facebook",
  dj: "dj",
  singer: "singer",
  orchestra: "orchestra",
  bandoneonist: "bandoneonist",
  guitarist: "guitarist",
  composer: "composer",
  quartet: "quartet",
  band: "band",
  musicianType: "musician - category",
  state: "state",
  county: "county",
  errorUsernameEmpty: "username is required",
  errorEmailEmpty: "email is required",
  errorEmailInvalid: "email is not valid",
  errorPasswordInvalid: "password is not valid",
  errorPasswordisLowercase: "add at least one lower case",
  errorPasswordisUppercase: "add at least one upper case",
  errorPasswordSpecialChars: "add at least one special character: #?!@$%^&*-",
  errorPasswordLength: "min 8 characters",
  errorPassword2Empty: "confirm password is required",
  errorPassword2Equals: "confirm password did not match",
  errorUsernameExist: "Username already exists",
  errorEmailExist: "Email already exists",
  passwordErrorInfo: "Password information",
  errorNotEmptyValues: "Fields not filled",
  passwordInfo:
    "Password: At least one upper case English letter, at least one lower case English letter, at least one digit, at least one special character (#?!@$%^&*-), minimum 8 in length",
  ads: "Ads",
  clientAd: "ad",
  addAd: "Add/Edit ad",
  leadAds: "Lead ads",
  here: "here",
  photoUpload: "Photo upload",
  adDescription: "tell us how do you want your ad",
  selectOption: "Select an option",
  bePartOfProject: "how to be part of this project",
  highlighted: "highlighted",
  events: "events",
  paymentOptions: "payment options",
  bannerSize: "Banner size",
  bannerDesign: "Banner design",
  bannerHighlight: "Banner highlighted",
  linkToClientWeb: "Link to your website",
  gifDesign: "Gif design",
  facebookDisution: "Facebook difusion",
  InstagramDifusion: "Instagram difusion",
  notificacionesPush: "Notification push",
  emailNotification: "Email notification",
  mapDotClient: "Geo marker in the map",
  mapdotDetailCli: "Detailed info about your event in Bailo.app",
  onMobileCli: "Your info in the mobile app",
  onWebCli: "Your info in the website",
  goGoogleCli: "How to get there via google maps",
  anualPriceCli: "Price (no taxes included)",
  getPlan: "get plan",
  taxes: "Taxes",
  list: "List",
  currentImg: "Current image",
  download: "Download",
  successAdMessage: "Your Ad was added!",
  successEditAdMessage: "Your Ad was edited correctly!",
  userRol: "User",
  adminRol: "Admin",
  adsNotFound: "Ads not found",
  details: "Details",
  priceDescription:
    "Start using the Bailo.app platform today and discover a new way to promote your tango product or service to the world.",
  whatsappContact:
    "Do you know a milonga that isn’t on Bailo.app yet? Drop us a message!",
  SendMessage: "Send a message",
};

export default eng;
