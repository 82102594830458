/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable import/extensions */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */

// NOTE: Simple Report es SIN necesidad de estar logged
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "moment-timezone";
import { toast } from "react-toastify";
import "../../../styles/scss/style.scss";
import { Button, Modal, Form } from "react-bootstrap";
import Text from "../../../components/Text";

class BailoDotSimpleReportsCard extends Component {
  state = {
    isReportOpen: false,
  };

  onClickSendReport = () => {
    this.setState({ isReportOpen: false });

    if (!this.props.reportMessage) {
      toast.error("El mensaje de reporte está vacío.");
      return;
    }

    this.props.handleReportError(); // Ahora sí envía el mensaje actualizado
  };

  render() {
    const { mapdot, reportMessage, history } = this.props;

    return (
      <>
        <div className="text-left">
          <button
            type="button"
            className="btn btn-outline-success"
            onClick={this.props.handleReportConfirm}
          >
            <i className="feather icon-check-circle"></i>
            <Text tid="confirm" />
          </button>

          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() =>
              this.setState({
                isReportOpen: true,
              })
            }
          >
            <i className="feather icon-alert-triangle"></i>
            <Text tid="report" />
          </button>
        </div>

        {/* Report section */}
        <Modal
          show={this.state.isReportOpen}
          onHide={() => this.setState({ isReportOpen: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title as="h5">{mapdot.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>
                  <Text tid="reportErrorDescription" />
                </Form.Label>
                <Form.Control
                  id="messageError"
                  name="messageError"
                  as="textarea"
                  type="text"
                  defaultValue={reportMessage} // 🟢 Deja esto si antes funcionaba
                  onChange={(e) => this.props.handleChange(e)} // 🟢 Asegura que el cambio se propague correctamente
                  rows="3"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ isReportOpen: false })}
            >
              <Text tid="closeButton" />
            </Button>

            <Button
              variant="primary"
              value={this.reportMessage}
              onClick={this.onClickSendReport}
            >
              <Text tid="saveChanges" />
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

BailoDotSimpleReportsCard.propTypes = {
  auth: PropTypes.object.isRequired,
  lang: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
});

export default withRouter(connect(mapStateToProps)(BailoDotSimpleReportsCard));
