/* eslint-disable react/prop-types */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
/* eslint-disable no-console */
/* eslint-disable no-return-assign */
import React, { Component } from "react";
import { connect } from "react-redux";
// import { GoogleLogin } from "react-google-login";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { PropTypes } from "prop-types";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import classnames from "classnames";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
import Aux from "../../hoc/_Aux/Aux";
// import Breadcrumb from "../../components/Breadcrumb";
import Loading from "../../components/Loading";

// import roleService from "../../services/roleService";

// import { handleSignup } from "../../store/actions/authActions";
import {
  registerUser,
  googleRegisterUser,
  // facebookRegisterUser,
} from "../../store/actions/authActions";
import Text from "../../components/Text";

ReactGA.initialize(process.env.REACT_APP_GA_ID_TRACKING);

class Signup extends Component {
  state = {
    username: "",
    password: "",
    password2: "",
    emailUser: "",
    isPasswordVisible: false,
    chkCustom: false,
    // danceRole: '',
    // myCity: '',
    // myCountry: '',
    // myPostalCode: '',
    // roles: [],
    // roleIdName: "leader",
    errors: {},
    loading: true,
    sendingEmail: false,
  };

  async componentDidMount() {
    ReactGA.pageview("signUp" + window.location.search);
    try {
      // const roles = await roleService.getAllRoleNames();
      this.setState({
        // roles,
        loading: false,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      this.setState({
        loading: false,
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleFormSubmit = async (e) => {
    e.preventDefault();

    // this.setState({ sendingEmail: true });

    try {
      // const { username, password } = this.state;
      const user = {
        username: this.state.username.trim(),
        password: this.state.password,
        password2: this.state.password2,
        emailUser: this.state.emailUser.trim(),
        // // danceRole: this.state.danceRole,
        // myCity: this.state.myCity,
        // myCountry: this.state.myCountry,
        // myPostalCode: this.state.myPostalCode,
        // roleIdName: this.state.roleIdName,
      };

      // this.props.handleSignup(user);
      // eslint-disable-next-line react/prop-types
      // this.props.registerUser(user, this.props.history);
      this.props.registerUser(user);
      this.setState({
        sendingEmail: true,
      });

      // this.form.reset()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      toast.error("😧 ERROR!");
      toast.info("🤔 Refresh the page and try again");
    }
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.errors) !== "{}") {
      this.setState({ errors: nextProps.errors });

      // google analytics
      ReactGA.exception({
        description: `Signup input error: ${nextProps.errors}`,
        fatal: true,
      });
    } else {
      this.setState({
        username: "",
        password: "",
        password2: "",
        emailUser: "",
        errors: {},
      });

      this.props.history.push("/");
    }
  }

  handleCheckboxChange = () => {
    // eslint-disable-next-line no-unneeded-ternary
    const chkCustom = this.state.chkCustom === false ? true : false;

    this.setState({
      chkCustom,
    });
  };

  responseGoogle = async (response) => {
    const tokenId = response?.tokenId;

    this.props.googleRegisterUser(tokenId);
  };

  googleFailure = (err) => {
    console.error(`Google Failure: ${err}`);
    toast.error("😧 Sign up was unsuccessful. Try again");
  };

  responseFacebook = (response) => {
    const newUser = {
      tokenId: response?.accessToken,
      username: response?.name,
      email: response?.email,
      picture: response?.picture.data.url,
      email_verified: true,
      _id: response?.id,
    };

    this.props.facebookRegisterUser(newUser);
  };

  render() {
    const {
      username,
      emailUser,
      password,
      password2,
      errors,
      chkCustom,
      /* danceRole, */
      /* myCity, myCountry, myPostalCode, roles, */
      loading,
      // sendingEmail,
    } = this.state;

    const { dictionary } = this.props.lang;
    return (
      <Aux>
        {/* <Breadcrumb /> */}
        {loading && <Loading />}
        {!loading && (
          <div className="auth-wrapper">
            <div className="auth-content">
              <div className="auth-bg">
                <span className="r" />
                <span className="r s" />
                <span className="r s" />
                <span className="r" />
              </div>
              <div className="card">
                <div className="card-body text-center">
                  <div className="mb-4">
                    <i className="feather icon-unlock auth-icon" />
                  </div>
                  <h3 className="mb-4">
                    <Text tid="signupCap" />
                  </h3>
                  <form
                    onSubmit={this.handleFormSubmit}
                    // ref={form => this.form = form}
                  >
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        name="username"
                        placeholder={dictionary.username}
                        // className="form-control"
                        className={classnames("form-control", {
                          "is-invalid": errors.username,
                        })}
                        id="username"
                        value={username}
                        onChange={this.handleChange}
                        // ref={input => this.username = input}
                      />
                      {errors && (
                        <div className="invalid-feedback">
                          {dictionary[errors.username]}
                        </div>
                      )}
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        name="emailUser"
                        placeholder={dictionary.email}
                        className={classnames("form-control", {
                          "is-invalid": errors.emailUser,
                        })}
                        value={emailUser}
                        onChange={this.handleChange}
                        // ref={input => this.emailUser = input}
                        id="emailUser"
                      />
                      {errors && (
                        <div className="invalid-feedback">
                          {dictionary[errors.emailUser]}
                        </div>
                      )}
                      {/* <input
                      className="form-control"
                      type="password"
                      name="password"
                      id="password"
                      value={password}
                      onChange={this.handleChange}
                      placeholder="password"
                    /> */}
                    </div>
                    {/* <div className="input-group mb-4">
                      <input
                        type="text"
                        name="myCity"
                        placeholder="Your city"
                        className={classnames("form-control", {
                          "is-invalid": errors.myCity
                        })}
                        value={myCity}
                        onChange={this.handleChange}
                        id="myCity"

                      />
                      {errors && (
                        <div className="invalid-feedback">{errors.myCity}</div>
                      )}
                    </div> */}
                    {/* <div className="input-group mb-4">
                      <input
                        type="text"
                        name="myCountry"
                        placeholder="Your country"
                        className={classnames("form-control", {
                          "is-invalid": errors.myCountry
                        })}
                        value={myCountry}
                        onChange={this.handleChange}
                        id="myCountry"

                      />
                      {errors && (
                        <div className="invalid-feedback">{errors.myCountry}</div>
                      )}
                    </div> */}
                    {/* <div className="input-group mb-4">
                      <input
                        type="text"
                        name="myPostalCode"
                        placeholder="Your postal code"
                        className={classnames("form-control", {
                          "is-invalid": errors.myPostalCode
                        })}
                        value={myPostalCode}
                        onChange={this.handleChange}
                        id="myPostalCode"

                      />
                      {errors && (
                        <div className="invalid-feedback">{errors.myPostalCode}</div>
                      )}
                    </div> */}
                    {/* <div className="input-group mb-4"> */}
                    {/* <input
                      type="text"
                      name="danceRole"
                      placeholder="Dance role: leader, follower, both"
                      className={classnames("form-control", {
                        "is-invalid": errors.danceRole
                      })}
                      value={danceRole}
                      onChange={this.handleChange}
                      id="danceRole"

                    />
                    {errors && (
                      <div className="invalid-feedback">{errors.danceRole}</div>
                    )} */}
                    {/* <select
                        type="text"
                        name="roleIdName"
                        id="roleIdName"
                        defaultValue={roles[0].roleName}
                        className={classnames("form-control", {
                          "is-invalid": errors.roleIdName
                        })}
                        onChange={this.handleChange}
                      >
                        {roles.map((role) => (
                          <option key={role._id} value={role.roleName}> */}
                    {/* {dictionary[dance.danceName]} */}
                    {/* {role.roleName}
                          </option>
                        ))}
                      </select>
                      {errors && (
                        <div className="invalid-feedback">{errors.roleIdName}</div>
                      )}
                    </div> */}

                    {Object.keys(errors).length !== 0 && (
                      <div className="input-group mb-3">
                        <div
                          style={{
                            border: "1px solid #f1f1f1",
                            textAlign: "left",
                            borderRadius: "5px",
                          }}
                        >
                          <p style={{ textAlign: "center" }}>
                            {dictionary.passwordErrorInfo}
                          </p>
                          <p style={{ marginLeft: "5px" }}>
                            {dictionary.errorPasswordisLowercase}
                          </p>
                          <p style={{ marginLeft: "5px" }}>
                            {dictionary.errorPasswordisUppercase}
                          </p>
                          <p style={{ marginLeft: "5px" }}>
                            {dictionary.errorPasswordSpecialChars}
                          </p>
                          <p style={{ marginLeft: "5px" }}>
                            {dictionary.errorPasswordLength}
                          </p>
                        </div>
                      </div>
                    )}

                    <div className="input-group mb-3">
                      <input
                        type={
                          !this.state.isPasswordVisible ? "password" : "text"
                        }
                        name="password"
                        autoComplete="off"
                        placeholder={dictionary.password}
                        // className="form-control"
                        className={classnames("form-control", {
                          "is-invalid": errors.password,
                        })}
                        value={password}
                        onChange={this.handleChange}
                        // ref={input => this.password = input}
                        id="password"
                      />
                      <button
                        type="button"
                        className="toggleButton"
                        onClick={() =>
                          this.setState({
                            isPasswordVisible: !this.state.isPasswordVisible,
                          })
                        }
                      >
                        {this.state.isPasswordVisible ? (
                          <i className="fa fa-eye"></i>
                        ) : (
                          <i className="fa fa-eye-slash"></i>
                        )}
                      </button>
                      {errors && (
                        <div className="invalid-feedback">
                          {dictionary[errors.password]}
                        </div>
                      )}
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type={
                          !this.state.isPasswordVisible ? "password" : "text"
                        }
                        name="password2"
                        autoComplete="off"
                        placeholder={
                          dictionary.confirm + " " + dictionary.password
                        }
                        className={classnames("form-control", {
                          "is-invalid": errors.password2,
                        })}
                        value={password2}
                        onChange={this.handleChange}
                        // ref={input => this.password2 = input}
                        id="password2"
                      />
                      {errors && (
                        <div className="invalid-feedback">
                          {dictionary[errors.password2]}
                        </div>
                      )}
                    </div>

                    <div className="input-group">
                      <input
                        type="checkbox"
                        id="checkbox"
                        name="checkbox"
                        defaultChecked={chkCustom}
                        value={chkCustom}
                        onChange={this.handleCheckboxChange}
                        className="input-group text-center"
                      ></input>
                    </div>

                    <div
                      // className="input-group mb-5"
                      className="mb-4"
                    >
                      <div style={{ paddingTop: "10px" }}>
                        <h6>
                          <Text tid="acceptTUPPOne" />
                        </h6>
                      </div>
                      <div>
                        <a
                          href="/terms-of-use"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <h6 style={{ color: "#4e6ac5" }}>
                            <Text tid="termsOfUse" />
                          </h6>
                        </a>
                      </div>
                      <div>
                        <h6>
                          <Text tid="acceptTUPPTwo" />
                        </h6>
                      </div>
                      <div>
                        <a
                          href="/privacy-policy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <h6 style={{ color: "#4e6ac5" }}>
                            <Text tid="privacyPolicy" />
                            <Text tid="finalDot" />
                          </h6>
                        </a>
                      </div>
                    </div>
                    <div style={{ paddingBottom: "20px" }}>
                      <Button
                        disabled={
                          !(
                            this.state.username &&
                            this.state.password &&
                            this.state.password2 &&
                            this.state.emailUser &&
                            this.state.chkCustom === true
                          )
                        }
                        // variant="primary"
                        type="submit"
                        style={{ margin: "0px" }}

                        // className="btn btn-primary shadow-2 mb-4"
                        // value="Signup"
                        // disabled={sendingEmail}
                      >
                        {/* {sendingEmail
                        ? <Loading />
                        : <Text tid="signupCap" />
                      } */}
                        <Text tid="signupCap" />
                      </Button>
                    </div>
                  </form>
                  <div style={{ paddingBottom: "15px" }}>
                    {/* TODO: deprecado por el momento 
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_LOGIN}
                      render={(renderProps) => (
                        <button
                          className="btn btn-primary shadow-2 mb-4"
                          onClick={renderProps.onClick}
                          variant="contained"
                          disabled={!(this.state.chkCustom === true)}
                        >
                          <i className="fa fa-google" aria-hidden="true" />
                          <Text tid="googleSignup" />
                        </button>
                      )}
                      onSuccess={this.responseGoogle}
                      onFailure={this.googleFailure}
                      cookiePolicy={"single_host_origin"}
                    /> */}
                    {/* TODO: deprecado por el momento 
                    <FacebookLogin
                      appId={process.env.REACT_APP_FACEBOOK_APPID}
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={this.responseFacebook}
                      render={(renderProps) => (
                        <button
                          className="btn btn-primary shadow-2 mb-4"
                          onClick={renderProps.onClick}
                          variant="contained"
                          disabled={!(this.state.chkCustom === true)}
                        >
                          <i className="fa fa-facebook" aria-hidden="true" />
                          <Text tid="facebookLogin" />
                        </button>
                      )}
                    /> */}
                  </div>
                  <p className="mb-0 text-muted">
                    <Text tid="haveAnAccount" />
                    <NavLink to={"/login"}>
                      {" "}
                      <Text tid="login" />
                    </NavLink>
                  </p>
                  <p className="mb-0 text-muted">
                    <Text tid="backTo" />{" "}
                    <NavLink to="/">
                      <Text tid="homepage" />
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Aux>
    );
  }
}

Signup.propTypes = {
  registerUser: PropTypes.func.isRequired,
  googleRegisterUser: PropTypes.func.isRequired,
  facebookRegisterUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  lang: state.lang,
});

export default connect(mapStateToProps, {
  registerUser,
  googleRegisterUser,
  // facebookRegisterUser,
})(Signup);
