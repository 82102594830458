/* eslint-disable spaced-comment */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable no-multi-assign */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  // OverlayTrigger,
  // Tooltip,
  // Container,
  // SplitButton,
  DropdownButton,
  Dropdown,
  Row,
  Col,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import moment from "moment";
import { Icon } from "leaflet";

import styled from "styled-components";
import Text from "../../components/Text";
import Aux from "../../hoc/_Aux/Aux";
import mapdotService from "../../services/mapdotService";
import cityService from "../../services/cityService";
import postService from "../../services/postService";
import clientIpService from "../../services/clientIpService";
// import adService from "../../services/adService"; TODO: elminar el Services

import MapVenuList from "./components/MapVenuList";
import DownloadAppBanner from "./components/DownloadAppBanner";
import BailoMap from "./components/BailoMap";

import Loading from "../../components/Loading"; // inside .js file

// import Pagination from "../admin/components/Pagination";

require("react-leaflet-markercluster/dist/styles.min.css");

const { daysOfTheWeek, danceCategories } = require("../../helpers/arrays");

// Styles Components:
const ContentMap = styled.div`
  .markercluster-map {
    height: 77vh;
    z-index: 1;
  }
`;

class MainMap extends Component {
  state = {
    searchWidth: this.props.windowWidth < 992 ? 90 : 0,
    searchString: this.props.windowWidth < 992 ? "90px" : "",
    isOpen: this.props.windowWidth < 992,
    searchBy: "venue",
    filterString: "",
    dayOfWeek: "",
    mapdotsTodaySearchBy: [],
    citiesTodaySearchBy: [],
    clickedCityId: "",
    centerLatitude: 45.469149,
    centerLongitude: 9.181158,
    zoomMap: 7,
    visibilityForm: false,
    visibilityFormName: false,
    loading: true,
    isBasic: false,
    isVertically: false,
    isTooltip: false,
    isGrid: false,
    isScrolling: false,
    isLarge: false,
    isModalName: false,
    // title: ''
    filterStringName: "",
    mapdotsPerPage: 20,
    currentPage: 1,
    currentMapdots: [],
    totalMapdots: undefined,
    filteredDate: undefined,
    radioItems: {},
    // ads: {},
    ipCountryName: "",
    ipCity: "",
    ipCountryLat: "",
    ipCountryLong: "",
    hasPublicyity: false,
    isButtonActive: true,
    venueView: "map",
  };

  async componentDidMount() {
    const { searchBy } = this.state;

    // const { currentPage, mapdotsPerPage, filterStringName } = this.state;
    const { filterStringName } = this.state;

    try {
      const momentDate = moment();
      const filteredDay = await momentDate.format("dddd").toLowerCase();
      const filteredDate = await momentDate.format("YYYY-MM-DD");
      const {
        city: ipCity,
        country_name: ipCountryName,
        latitude: ipCountryLat,
        longitude: ipCountryLong,
      } = await this.getGeoInfoFromIp();

      // TODO: descomentar para testing. Simula que el ip es de un país diferente.
      // const ipCountryName = "Argent";
      // const ipCity = "Córdoba";
      // const ipCountryLat = 45.469149;
      // const ipCountryLong = 11.44;

      // TODO: quitar el getAdds y verificar su metodo searchAdsByCountry
      // const getAds = await adService.searchAdsByCountry(ipCountryName);
      const getMapdots = await this.loadMapdots(
        filteredDay.toLowerCase(),
        searchBy,
        filteredDate,
        ipCountryName
      );

      const filteredCities = await this.loadCities(getMapdots);

      if (filteredCities.some((c) => c.cityName === ipCity)) {
        this.setState({
          zoomMap: 13,
          centerLatitude: ipCountryLat,
          centerLongitude: ipCountryLong,
          ipCity,
          ipCountryName,
          hasPublicyity: true,
        });
      }

      // TODO: Seccion ex Banner. Hay que cambiarlo y dejar solo la logica de que obtiene la ciudad, segun la ip
      // if (getAds.some((c) => c.city === ipCity)) {
      //   this.setState({
      //     ipCity,
      //     ipCountryName,
      //     hasPublicyity: true,
      //   });
      // } else {
      this.setState({
        ipCity,
        ipCountryName,
        hasPublicyity: false,
      });
      // }

      // TODO: filter by default. First time the filter is empty so get all mapdots. Podria filtrar por lo q se obtiene de ipCity o ipCountryName
      const getCurrentMapdots = await this.loadCurrentMapdots(filterStringName);

      this.setState({
        dayOfWeek: filteredDay,
        filteredDate,
        mapdotsTodaySearchBy: getMapdots,
        citiesTodaySearchBy: filteredCities,
        loading: false,
        currentMapdots: getCurrentMapdots,
        totalMapdots: getCurrentMapdots.length, // TODO: it is not necesary
        // ads: getAds,
      });
    } catch (error) {
      console.log(error);
    }
  }

  setView = (venueView) => {
    this.setState({ venueView });
  };

  async getGeoInfoFromIp() {
    try {
      return await clientIpService.getIpLocation();
    } catch (error) {
      return error;
    }
  }

  async loadMapdots(day, category, filteredDate, ipCountryName) {
    try {
      return await mapdotService.searchMapdots(
        day,
        category,
        filteredDate,
        ipCountryName
      );
    } catch (error) {
      return error;
    }
  }

  async loadMapdotsNoDay(category) {
    try {
      return await mapdotService.searchMapdotsNoDay(category);
    } catch (error) {
      return error;
    }
  }

  async loadCurrentMapdots(filterStringName) {
    try {
      return await mapdotService.getPublicBailoDots(filterStringName);
    } catch (error) {
      return error;
    }
  }

  handleChangeFilter = async (event) => {
    // if (event.target.value.length === 1) {
    if (event.target.value.length >= 1) {
      // const { mapdotsPerPage, currentPage } = this.state;

      const filterStringName = event.target.value;

      const updatedCurrentMadots = await this.loadCurrentMapdots(
        filterStringName
      );

      // this.setState({
      //   filterStringName,
      //   totalMapdots: updatedCurrentMadots.length,
      //   currentMapdots: updatedCurrentMadots,
      // });

      this.setState({
        visibilityFormName: true,
        filterStringName,
        totalMapdots: updatedCurrentMadots.length, // TODO: remove it
        currentMapdots: updatedCurrentMadots,
      });
    } else if (event.target.value.length === 0) {
      this.setState({
        visibilityFormName: false,
        centerLatitude: 45.469149,
        centerLongitude: 9.181158,
        zoomMap: 3,
      });
    }
    await this.setState({
      filterStringName: event.target.value,
    });
  };

  async loadCities(getMapdots) {
    try {
      const filteredCityIds = await getMapdots
        .map((oneMapdot) => oneMapdot.cityObject.toString())
        .reduce((accumulator, currentValue) => {
          if (accumulator.indexOf(currentValue) === -1) {
            accumulator.push(currentValue);
          }
          return accumulator;
        }, []);

      return await cityService.searchCities(filteredCityIds);
    } catch (error) {
      return error;
    }
  }

  async getDayNameFromDate(date) {
    try {
      const d = new Date(date);
      let dayName;
      if (d.getDay() === 0) {
        dayName = daysOfTheWeek[6].day;
      } else if (d.getDay() === 1) {
        dayName = daysOfTheWeek[0].day;
      } else if (d.getDay() === 2) {
        dayName = daysOfTheWeek[1].day;
      } else if (d.getDay() === 3) {
        dayName = daysOfTheWeek[2].day;
      } else if (d.getDay() === 4) {
        dayName = daysOfTheWeek[3].day;
      } else if (d.getDay() === 5) {
        dayName = daysOfTheWeek[4].day;
      } else {
        dayName = daysOfTheWeek[5].day;
      }

      return dayName;
    } catch (error) {
      return error;
    }
  }

  async getDateFromDayName(dayName) {
    try {
      const shortDay = dayName.slice(0, 3);
      let date;

      const now = moment();
      const nowPlus1 = moment().add(1, "days");
      const nowPlus2 = moment().add(2, "days");
      const nowPlus3 = moment().add(3, "days");
      const nowPlus4 = moment().add(4, "days");
      const nowPlus5 = moment().add(5, "days");
      const nowPlus6 = moment().add(6, "days");

      if (now._d.toString().slice(0, 3).toLowerCase() === shortDay) {
        date = now.format("YYYY-MM-DD");
      } else if (
        nowPlus1._d.toString().slice(0, 3).toLowerCase() === shortDay
      ) {
        date = nowPlus1.format("YYYY-MM-DD");
      } else if (
        nowPlus2._d.toString().slice(0, 3).toLowerCase() === shortDay
      ) {
        date = nowPlus2.format("YYYY-MM-DD");
      } else if (
        nowPlus3._d.toString().slice(0, 3).toLowerCase() === shortDay
      ) {
        date = nowPlus3.format("YYYY-MM-DD");
      } else if (
        nowPlus4._d.toString().slice(0, 3).toLowerCase() === shortDay
      ) {
        date = nowPlus4.format("YYYY-MM-DD");
      } else if (
        nowPlus5._d.toString().slice(0, 3).toLowerCase() === shortDay
      ) {
        date = nowPlus5.format("YYYY-MM-DD");
      } else {
        date = nowPlus6.format("YYYY-MM-DD");
      }

      return date;
    } catch (error) {
      return error;
    }
  }

  handleChangeSearchBy = async (event) => {
    const { dayOfWeek, filteredDate } = this.state;

    let updatedMadots;
    const searchBy = event;

    this.setState({
      loading: true,
    });

    if (
      searchBy === "venue" ||
      searchBy === "practica" ||
      searchBy === "class"
    ) {
      updatedMadots = await this.loadMapdots(dayOfWeek, searchBy, filteredDate);
    } else {
      updatedMadots = await this.loadMapdotsNoDay(searchBy);

      const momentDate = moment();
      const filteredDay = await momentDate.format("dddd").toLowerCase();
      const getFilteredDate = await momentDate.format("YYYY-MM-DD");

      this.setState({
        dayOfWeek: filteredDay,
        filteredDate: getFilteredDate,
      });
    }

    const updatedCities = await this.loadCities(updatedMadots);

    this.setState({
      searchBy,
      mapdotsTodaySearchBy: updatedMadots,
      citiesTodaySearchBy: updatedCities,
      centerLatitude: 45.469149,
      centerLongitude: 9.181158,
      zoomMap: 3,
      loading: false,
    });
  };

  handleChangeDate = async (event) => {
    const { searchBy } = this.state;

    let updatedMadots;
    const filteredDate = event.target.value;
    const dayOfWeek = await this.getDayNameFromDate(event.target.value);

    this.setState({
      loading: true,
    });

    if (
      searchBy === "venue" ||
      searchBy === "practica" ||
      searchBy === "class"
    ) {
      updatedMadots = await this.loadMapdots(dayOfWeek, searchBy, filteredDate);
    } else {
      updatedMadots = await this.loadMapdotsNoDay(searchBy);
    }

    const updatedCities = await this.loadCities(updatedMadots);

    this.setState({
      filteredDate,
      dayOfWeek,
      mapdotsTodaySearchBy: updatedMadots,
      citiesTodaySearchBy: updatedCities,
      centerLatitude: 45.469149,
      centerLongitude: 9.181158,
      zoomMap: 3,
      loading: false,
    });
  };

  handleChangeCities = async (event) => {
    if (event.target.value.length === 1) {
      this.setState({
        visibilityForm: true,
      });
    } else if (event.target.value.length === 0) {
      this.setState({
        visibilityForm: false,
        centerLatitude: 45.469149,
        centerLongitude: 9.181158,
        zoomMap: 3,
      });
    }
    await this.setState({
      filterString: event.target.value,
    });
  };

  async handleClickCity(city) {
    const { cityName } = city;
    try {
      await postService.sendCity(cityName).catch((error) => {
        console.log(error);
      });
    } catch (error) {
      console.log(error);
    }

    this.setState({
      centerLatitude: city.coordinatesLatLongCity.Latitude,
      centerLongitude: city.coordinatesLatLongCity.Longitude,
      zoomMap: 13,
      isLarge: false,
      visibilityForm: false,
    });
  }

  async handleClickMapdot(mapdot) {
    const searchBy = mapdot.categoryIdName;
    let dayOfWeek = "";
    let filteredDate = "";
    let updatedMadots;

    this.setState({
      loading: true,
    });

    if (
      searchBy === "venue" ||
      searchBy === "practica" ||
      searchBy === "class"
    ) {
      dayOfWeek = mapdot.day;

      if (mapdot.dateOnce !== "") {
        filteredDate = mapdot.dateOnce;
      } else if (
        mapdot.dateStart !== "" &&
        mapdot.dateEnd !== "" &&
        searchBy === "class"
      ) {
        filteredDate = mapdot.dateStart;
      } else {
        filteredDate = await this.getDateFromDayName(dayOfWeek);
      }

      updatedMadots = await this.loadMapdots(dayOfWeek, searchBy, filteredDate);
    } else {
      const momentDate = moment();
      dayOfWeek = await momentDate.format("dddd").toLowerCase();
      filteredDate = await momentDate.format("YYYY-MM-DD");

      updatedMadots = await this.loadMapdotsNoDay(searchBy);
    }

    const updatedCities = await this.loadCities(updatedMadots);
    const bailoDotTitle =
      mapdot.title !== "" ? mapdot.title : mapdot.publicName;

    let lat;
    let lng;

    if (mapdot.coordinatesLatLong.Latitude) {
      lat = mapdot.coordinatesLatLong.Latitude;
      lng = mapdot.coordinatesLatLong.Longitude;
    } else {
      lat = mapdot.coordinatesLatLong.lat;
      lng = mapdot.coordinatesLatLong.lng;
    }

    try {
      await postService.sendBailoDot(bailoDotTitle).catch((error) => {
        console.log(error);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    this.setState({
      filteredDate,
      searchBy,
      dayOfWeek,
      mapdotsTodaySearchBy: updatedMadots,
      citiesTodaySearchBy: updatedCities,
      centerLatitude: lat,
      centerLongitude: lng,
      zoomMap: 15,
      isModalName: false,
      visibilityFormName: false,
      loading: false,
      filterStringName: "",
    });
  }

  render() {
    const {
      mapdotsTodaySearchBy,
      loading,
      searchBy,
      filterString,
      citiesTodaySearchBy,
      // filterString,
      // eslint-disable-next-line no-unused-vars
      filterStringName,
      visibilityForm,
      // eslint-disable-next-line no-unused-vars
      visibilityFormName,
      centerLatitude,
      centerLongitude,
      zoomMap,
      currentMapdots, // TODO: analizar este punto. Por que hay tantos mapdots cargados?
      filteredDate,
      // radioItems,
      // mapdotsPerPage,
      // totalMapdots,
      ipCity,
      // ipCountryName,
      // hasPublicyity,
      // isButtonActive,
      // ads,
      dayOfWeek,

      venueView,
    } = this.state;

    const { dictionary } = this.props.lang;

    const pin = new Icon({
      iconUrl:
        "https://res.cloudinary.com/dklijqv8g/image/upload/v1624803083/bailo_marker2_bilow4.png",
      iconSize: [35, 35],
      iconAnchor: [15, 30],
    });

    return (
      <Aux>
        {loading && <Loading />}
        {!loading && (
          <div>
            {/* -- TODO: Filter section -- */}
            <Row className="ml-5 align-items-center flex-nowrap">
              <Col xl="auto" className="d-flex align-items-center flex-wrap">
                <form onSubmit={this.handleSubmit}>
                  <div className="d-flex align-items-center flex-wrap">
                    <div
                      className="d-flex align-items-centermb-2"
                      style={{ marginRight: "30px" }}
                    >
                      <DropdownButton
                        type="text"
                        name="searchBy"
                        id="searchBy"
                        value={searchBy}
                        title={dictionary[searchBy]}
                        variant="outline-primary"
                        onSelect={this.handleChangeSearchBy}
                      >
                        {danceCategories.map((cat) => (
                          <Dropdown.Item
                            key={cat.id}
                            eventKey={cat.type}
                            value={cat.type}
                          >
                            {dictionary[cat.type]}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </div>

                    {(searchBy === "venue" ||
                      searchBy === "practica" ||
                      searchBy === "class") && (
                      <div className="date-container ">
                        <div className="input-icon-align">
                          <input
                            ref={(ref) => {
                              this.dateInputRef = ref;
                            }}
                            type="date"
                            name="dayOfWeek"
                            id="dayOfWeek"
                            value={filteredDate}
                            onChange={this.handleChangeDate}
                            className="form-control date-input"
                          />
                          <i
                            className="fa-regular fa-calendar calendar-icon"
                            onClick={() =>
                              this.dateInputRef &&
                              this.dateInputRef.showPicker()
                            }
                            onMouseEnter={() =>
                              this.setState({ isCalendarHover: true })
                            }
                            onMouseLeave={() =>
                              this.setState({ isCalendarHover: false })
                            }
                          />
                        </div>
                        <div
                          className="mt-0 text-center"
                          style={{ width: "100%" }}
                        >
                          <p className="date-text">{dictionary[dayOfWeek]}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </Col>

              {/* seccion Boton del filtro & lupa */}
              <Col xl="auto" className="d-flex align-items-center flex-wrap">
                <Button
                  variant="primary"
                  className="fa fa-building mr-3 mb-2"
                  onClick={() => this.setState({ isLarge: true })}
                  title={dictionary.searchFor}
                />
                <Button
                  variant="primary"
                  className="feather icon-search mr-3 mb-2"
                  onClick={() => this.setState({ isModalName: true })}
                  title={dictionary.searchForMilongaName}
                />
                {/* Seccion del switch para ver mapa o lista */}
                <Form.Check
                  type="switch"
                  id="view-switch"
                  label={
                    this.state.venueView === "map"
                      ? dictionary.map
                      : dictionary.list
                  }
                  checked={this.state.venueView === "map"}
                  onChange={() =>
                    this.setView(
                      this.state.venueView === "map" ? "list" : "map"
                    )
                  }
                  className="mr-3 mb-2"
                />
              </Col>
            </Row>

            <ContentMap>
              {/* -- TODO: Cards info milongas and MAP section -- */}
              <Row>
                {venueView === "list" ? (
                  <MapVenuList
                    mapdots={mapdotsTodaySearchBy}
                    currentCity={ipCity}
                  />
                ) : (
                  <BailoMap
                    mapdotsTodaySearchBy={mapdotsTodaySearchBy}
                    centerLatitude={centerLatitude}
                    centerLongitude={centerLongitude}
                    zoomMap={zoomMap}
                    pin={pin}
                  />
                )}

                {/* -- Download Banner Section -- */}
                <DownloadAppBanner />
              </Row>
            </ContentMap>

            {/* -- Modal para filtro --  */}

            <Modal
              size="lg"
              show={this.state.isLarge}
              onHide={() =>
                this.setState({ isLarge: false, visibilityForm: false })
              }
            >
              <Modal.Body
                style={{
                  maxHeight: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
              >
                <form autoComplete="off">
                  <input
                    className="form-control"
                    type="search"
                    id="clickedCityId"
                    name="clickedCityId"
                    placeholder={dictionary.searchFor}
                    onChange={this.handleChangeCities}
                    aria-invalid="false"
                    autoComplete="off"
                    aria-autocomplete="list"
                  />
                  {filterString !== "" ? (
                    visibilityForm && (
                      <ul className="ul-text">
                        {citiesTodaySearchBy.filter((city) =>
                          city.cityName
                            .toLowerCase()
                            .match(filterString.toLowerCase())
                        ).length !== 0 ? (
                          citiesTodaySearchBy
                            .filter((city) =>
                              city.cityName
                                .toLowerCase()
                                .match(filterString.toLowerCase())
                            )
                            .map((city) => (
                              <li
                                className="li-text"
                                key={city._id}
                                value={city._id}
                                onClick={() => this.handleClickCity(city)}
                              >
                                <a href="#" key={city._id}>
                                  {city.cityName}
                                </a>
                              </li>
                            ))
                        ) : (
                          <div style={{ fontFamily: `Montserrat` }}>
                            <Text tid="noResultsfound" />
                          </div>
                        )}
                      </ul>
                    )
                  ) : (
                    <div></div>
                  )}
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => this.setState({ isLarge: false })}
                >
                  {dictionary.closeButton}
                </Button>
              </Modal.Footer>
            </Modal>

            {/* -- modal para search (lupa) -- */}

            <Modal
              size="lg"
              show={this.state.isModalName}
              onHide={() =>
                this.setState({
                  isModalName: false,
                  visibilityFormName: false,
                  filterStringName: "",
                })
              }
            >
              <Modal.Body
                style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
              >
                <form autoComplete="off">
                  <input
                    className="form-control"
                    type="search"
                    id="clickedMilongaId"
                    name="clickedMilongaId"
                    placeholder={dictionary.searchForMilongaName}
                    onChange={this.handleChangeFilter}
                    value={this.state.filterStringName}
                    autoComplete="off"
                  />
                  {this.state.filterStringName !== "" &&
                    this.state.visibilityFormName && (
                      <div>
                        <Table responsive hover>
                          <tbody>
                            {currentMapdots.filtered.length !== 0 ? (
                              currentMapdots.filtered
                                .slice(0)
                                .reverse()
                                .map((mapdot) => (
                                  <tr
                                    className="li-text"
                                    key={mapdot._id}
                                    value={mapdot._id}
                                    onClick={() =>
                                      this.handleClickMapdot(mapdot)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <td>
                                      <span
                                        style={{
                                          textDecoration: "underline",
                                          color: "#007bff",
                                        }}
                                      >
                                        {mapdot.title !== ""
                                          ? mapdot.title
                                          : mapdot.publicName}
                                      </span>
                                    </td>
                                    <td>
                                      {mapdot.city}
                                      <br />
                                      {dictionary[mapdot.categoryIdName]}
                                      <br />
                                      {[
                                        "school",
                                        "teacher",
                                        "radio",
                                        "musician",
                                      ].includes(mapdot.categoryIdName)
                                        ? null
                                        : dictionary[mapdot.day]}
                                    </td>
                                  </tr>
                                ))
                            ) : (
                              <tr>
                                <td style={{ fontFamily: `Montserrat` }}>
                                  <Text tid="noResultsfound" />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    )}
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() =>
                    this.setState({
                      isModalName: false,
                      filterStringName: "",
                    })
                  }
                >
                  {dictionary.closeButton}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </Aux>
    );
  }
}

MainMap.propTypes = {
  auth: PropTypes.object.isRequired,
  lang: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
  layout: state.layout,
});

export default connect(mapStateToProps)(MainMap);
