/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import { Col, Card } from "react-bootstrap";
import { connect } from "react-redux";
import iphoneMoc from "../../../styles/images/landing/iPhone11.png";

const DownloadAppBanner = (props) => {
  const { dictionary } = props.lang;

  return (
    <Col xl="4" md="12">
      <Card style={{ height: "45vh" }}>
        <div className="msg-publicity">
          <div className="m-3">
            <Card.Body>
              <div style={{ height: "calc(130vh - 340px)" }}>
                <div className="grid">
                  <figure className="effect-ming" style={{ height: "40vh" }}>
                    <img src={iphoneMoc} alt="Download App Store" />
                    <figcaption style={{ height: "40vh" }}>
                      <h2
                        className="text-center"
                        style={{
                          fontFamily: `Montserrat`,
                          fontSize: "35px",
                          textShadow: "1px 1px 2px rgba(0,0,0,0.3)",
                        }}
                      >
                        {dictionary.downloadApp.split(" ")[0]}
                        <br />
                        {dictionary.downloadApp.split(" ")[1]}
                      </h2>
                      <div>
                        <p className="m-4">
                          <i className="feather icon-target fa-3x" />
                          {/* <a href="#downloadSection"> */}
                          {/* </a> */}
                        </p>
                      </div>
                      <a href="#downloadSection"></a>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </Card.Body>
          </div>
        </div>
      </Card>

      <Card style={{ height: "20em" }}>
        <div className="msg-publicity">
          <div className="m-3">
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {/* Sección con gradiente y contenido */}
              <div
                style={{
                  height: "20%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  background:
                    "linear-gradient(153.5deg, #54aa7c 0%, #4e6ac5 44.75%, #c870c8 100%)",
                  color: "white",
                  padding: "20px",
                }}
              >
                {/* Texto centrado */}
                <p
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    fontFamily: `Montserrat`,
                  }}
                >
                  {dictionary.whatsappContact}
                </p>

                {/* Icono de WhatsApp */}
                <a
                  href="https://chat.whatsapp.com/HPoSl8lt4EL2RkCyQ4up00"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "white",
                    fontSize: "1.5em",
                  }}
                >
                  <i
                    className="fa-brands fa-whatsapp"
                    style={{ fontSize: "2em", marginRight: "8px" }}
                  ></i>
                  {dictionary.SendMessage}
                </a>
              </div>
            </Card.Body>
          </div>
        </div>
      </Card>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
  layout: state.layout,
});

export default connect(mapStateToProps)(DownloadAppBanner);
