/* eslint-disable class-methods-use-this */
import axios from "axios";

class MapdotService {
  async getAllMapdots() {
    const { data: mapdots } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots`,
      {},
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return mapdots;
  }

  async getFilteredMapdots(
    filterString,
    mapdotsPerPage,
    currentPage,
    searchBy
  ) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/filter`,
      {
        params: {
          filterString,
          mapdotsPerPage,
          currentPage,
          searchBy,
        },
      },
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  async getPublicBailoDots(
    filterStringName
    // mapdotsPerPage,
    // currentPage,
  ) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/public-filter`,
      {
        params: {
          filterStringName,
          // mapdotsPerPage,
          // currentPage,
        },
      },
      {}
    );
    return data;
  }

  async searchMapdots(day, category, filteredDate, ipCountryName) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/search`,
      {
        params: {
          day,
          category,
          filteredDate,
          ipCountryName,
        },
      },
      {}
    );
    return data;
  }

  async searchMapdotsNoDay(category) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/search-no-day`,
      {
        params: {
          category,
        },
      },
      {}
    );
    return data;
  }

  async getAllApprovedMapdots() {
    const { data: mapdots } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/approved`,
      {},
      {}
    );
    return mapdots;
  }

  async getAllPendingMapdots() {
    const { data: mapdots } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/pending`,
      {},
      {}
    );
    return mapdots;
  }

  async getAllRejectedMapdots() {
    const { data: mapdots } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/rejected`,
      {},
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return mapdots;
  }

  async getAllOnHoldMapdots() {
    const { data: mapdots } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/onhold`,
      {},
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return mapdots;
  }

  async getMapdotById(id) {
    const { data: mapdot } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/${id}`,
      {},
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return mapdot;
  }

  async getMapdotByIdForEdit(id) {
    const { data: mapdot } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/${id}/info-for-edit`,
      {},
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return mapdot;
  }

  async getMapdotLikesById(id) {
    const { data: mapdot } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/${id}/likes`,
      {},
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return mapdot;
  }

  async uploadPhoto(mapdotId, imageUrl) {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/${mapdotId}/upload-photo`,
      imageUrl,
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  async addMapdot(
    title,
    description,
    // dateStart,
    // dateEnd,
    timeStart,
    timeEnd,
    price,
    address,
    addressPlus,
    postalCode,
    city,
    country,
    categoryIdName,
    danceIdName,
    phoneNr,
    emailMapdot,
    frequency,
    website,
    day,
    musician,
    realOwner,
    activeSince,
    musicStyle,
    dateStart,
    dateEnd,
    dateOnce,
    offersPrivateLesson,
    offersOnlineLesson,
    classLevel,
    termsOfClass,
    publicName,
    schoolId,
    teacherId,
    belongsToRadioStation,
    belongsToPodcastPlatform,
    languageSpokenRadio
    // language,
    // useReverseGeo,
    // userLatitude,
    // userLongitude,
  ) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/add`,
      {
        title,
        description,
        // dateStart,
        // dateEnd,
        timeStart,
        timeEnd,
        price,
        address,
        addressPlus,
        postalCode,
        city,
        country,
        categoryIdName,
        danceIdName,
        phoneNr,
        emailMapdot,
        frequency,
        website,
        day,
        musician,
        realOwner,
        activeSince,
        musicStyle,
        dateStart,
        dateEnd,
        dateOnce,
        offersPrivateLesson,
        offersOnlineLesson,
        classLevel,
        termsOfClass,
        publicName,
        schoolId,
        teacherId,
        belongsToRadioStation,
        belongsToPodcastPlatform,
        languageSpokenRadio,
        // language,
        // useReverseGeo,
        // userLatitude,
        // userLongitude,
      },
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  async updateMapdot(
    title,
    description,
    // dateStart,
    // dateEnd,
    timeStart,
    timeEnd,
    price,
    address,
    addressPlus,
    city,
    country,
    // categoryIdName,
    // danceIdName,
    phoneNr,
    emailMapdot,
    frequency,
    website,
    day,
    musician,
    postalCode,
    mapdotId,
    activeSince,
    musicStyle,
    dateStart,
    dateEnd,
    dateOnce,
    offersPrivateLesson,
    offersOnlineLesson,
    classLevel,
    termsOfClass,
    publicName,
    schoolId,
    teacherId,
    belongsToRadioStation,
    belongsToPodcastPlatform,
    languageSpokenRadio
  ) {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/${mapdotId}/edit`,
      {
        title,
        description,
        // dateStart,
        // dateEnd,
        timeStart,
        timeEnd,
        price,
        address,
        addressPlus,
        city,
        country,
        // categoryIdName,
        // danceIdName,
        phoneNr,
        emailMapdot,
        frequency,
        website,
        day,
        musician,
        postalCode,
        activeSince,
        musicStyle,
        dateStart,
        dateEnd,
        dateOnce,
        offersPrivateLesson,
        offersOnlineLesson,
        classLevel,
        termsOfClass,
        publicName,
        schoolId,
        teacherId,
        belongsToRadioStation,
        belongsToPodcastPlatform,
        languageSpokenRadio,
      },
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  async likeMapdot(id) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/${id}/add-like`,
      {},
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  async addComment(id, titleOfUser, checkStarsOfUser) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/${id}/add-comment`,
      {
        titleOfUser,
        checkStarsOfUser,
      },
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  async putMapdotOnHold(mapdotId) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/${mapdotId}/onhold-mapdot`,
      {},
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  async approveMapdot(mapdotId, mapdotLatLong) {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/${mapdotId}/approve-mapdot`,
      {
        mapdotLatLong,
      },
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  async findMapdotLatLong(mapdotId, address, city, country) {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/${mapdotId}/findlatlong-mapdot`,
      {
        address,
        city,
        country,
      },
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  async findMapdotAddressCityCountry(mapdotId, userLatitude, userLongitude) {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/${mapdotId}/addressCityCountry-mapdot`,
      {
        userLatitude,
        userLongitude,
      },
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  // async adminDeletesMapdot(
  //   mapdotId,
  // ) {
  //   const { data } = await axios
  //     .delete(`${process.env.REACT_APP_BACKEND_URI}/api/mapdots/${mapdotId}/mapdot-delete`,
  //       {},
  //       {
  //         headers: {
  //           Authorization: `${localStorage.jwtToken}`,
  //         },
  //       },
  //     );
  //   return data;
  // }

  async deleteMapdot(id) {
    const { data: mapdots } = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/${id}/delete`,
      {},
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return mapdots;
  }

  async deleteCommentByAuthor(id) {
    const { data: mapdots } = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/${id}/delete-comment`,
      {},
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return mapdots;
  }

  async updateImageMeeting(mapdotId, hasAnImgForMeetings) {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URI}/api/mapdots/${mapdotId}/edit-has-an-image-meeting`,
      {
        hasAnImgForMeetings,
      },
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }
}

const mapdotService = new MapdotService();

export default mapdotService;
