/* eslint-disable */
import React, { Component } from "react";
import { Card, Button, Modal, Col, Table } from "react-bootstrap";
import { connect } from "react-redux";
import "moment-timezone";
import moment from "moment";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Map, Marker, TileLayer } from "react-leaflet";
import BailoDotContactCard from "./components/BailoDotContactCard";
import BailoDotAddressCard from "./components/BailoDotAddressCard";
import Text from "../../components/Text";
import BailoDotSimpleReportsCard from "./components/BailoDotSimpleReportsCard";
import L from "leaflet";
import { toast } from "react-toastify";
// service section
import reportService from "../../services/reportService";

// Definir icono del marcador
const pin = L.icon({
  iconUrl: "https://img.icons8.com/officel/30/000000/marker.png",
  iconSize: [30, 30],
  iconAnchor: [15, 30],
});

class MapdotPopup extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      show: false,
      loading: false,
      mapdotImgUrl: "",
      like: false,
      commentOfUser: "",
      starsOfUser: 0,
      titleOfUser: "",
      editForm: false,
      userRole: "user",
      reportMessage: "",
      reportLastConfirmation: "",
      mapdotId: props.mapdot ? props.mapdot._id : "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mapdot !== this.props.mapdot) {
      this.setState({ mapdotId: this.props.mapdot._id });
    }
  }

  // init report
  handleReportConfirm = async () => {
    const { mapdot } = this.props;
    const { _id } = mapdot;
    const now = moment();
    const date = now.format("YYYY-MM-DD");

    await reportService
      .addReportConfirmAllUsers(date, _id)
      .then(() => {
        toast.success("👍 Confirmed!");
      })
      .catch((error) => {
        toast.error("😧 ERROR! try again");
        console.log(error);
      });

    this.setState({
      reportLastConfirmation: moment(date).format("DD-MM-YYYY"),
    });
  };

  handleChange = (e) => {
    this.setState({
      reportMessage: e.target.value, // 🟢 Asegurar que se actualiza el estado
    });
  };

  handleReportError = async () => {
    const { reportMessage } = this.state;
    const { mapdot } = this.props; // 🔥 Obtenemos mapdot desde props
    const mapdotId = mapdot?._id; // 🔥 Extraemos el ID correctamente

    if (!mapdotId) {
      toast.error("😧 Error: No se encontró el ID del mapdot.");
      return;
    }

    const now = moment();
    const date = now.format("YYYY-MM-DD");

    try {
      await reportService.addReportErrorAllUsers(reportMessage, date, mapdotId);
      toast.success("👍 Confirmed!");
      this.setState({
        reportMessage: "",
      });
    } catch (error) {
      console.error("❌ Error al enviar reporte:", error);
      toast.error("😧 ERROR! try again");
    }
  };

  // fin report

  handleShow = () => {
    this.setState({ show: true }, () => {
      // Timeout corto para asegurar que el modal ya se montó
      setTimeout(() => {
        if (this.mapRef.current) {
          this.mapRef.current.leafletElement.invalidateSize();
        }
      }, 200);
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    const { mapdot } = this.props;
    const { show, loading, reportLastConfirmation, reportMessage } = this.state;
    // Verificar si mapdot.isMapdotConfirmed existe
    const lastConfirmedDate = mapdot.isMapdotConfirmed
      ? moment(mapdot.isMapdotConfirmed, "YYYY-MM-DD")
      : null;

    const threeMonthsAgo = moment().subtract(3, "months");
    const isRecentConfirmation =
      lastConfirmedDate && lastConfirmedDate.isAfter(threeMonthsAgo);

    // Extraer valores correctamente
    const latitude = mapdot?.coordinatesLatLong?.lat || "";
    const longitude = mapdot?.coordinatesLatLong?.lng || "";
    const categoryIdName = mapdot?.categoryIdName || "";

    return (
      <div>
        {/* Título clickable */}
        <h5
          onClick={this.handleShow}
          style={{
            cursor: "pointer",
            color: "#4e6ac5",
            textDecoration: "none",
            fontWeight: "bold",
          }}
        >
          {mapdot.title}
        </h5>

        {/* Modal con la información detallada */}
        <Modal
          show={show}
          onHide={this.handleClose}
          centered
          dialogClassName="responsive-modal" // clase para controlar el tamaño y evitar scroll horizontal
        >
          <Modal.Body>
            {loading && <p>Cargando...</p>}
            {!loading && (
              <Col sm={12}>
                <Card className="Recent-Users">
                  <Card.Header>
                    <Card.Title as="h5">
                      {mapdot.title !== "" ? (
                        <blockquote>{mapdot.title}</blockquote>
                      ) : (
                        <blockquote>{mapdot.publicName}</blockquote>
                      )}
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="pb-0">
                    <Table responsive hover>
                      <tbody>
                        <BailoDotAddressCard mapdot={mapdot} />
                      </tbody>

                      {/* Mapa y botón de Google Maps */}
                      {latitude && longitude && (
                        <tr className="unread">
                          <td colSpan="4">
                            {categoryIdName !== "teacher" &&
                              categoryIdName !== "radio" &&
                              categoryIdName !== "musician" && (
                                <a
                                  href={`https://maps.google.com?q=${latitude},${longitude}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="btn btn-primary"
                                >
                                  <i className="fa-solid fa-directions" />
                                  <Text tid="goGoogle" />
                                </a>
                              )}

                            <Map
                              ref={this.mapRef}
                              style={{
                                height: "50vh",
                                width: "100%",
                                margin: "10px 0",
                              }}
                              center={[latitude, longitude]}
                              zoom={15}
                              scrollWheelZoom={false}
                            >
                              <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              />
                              <MarkerClusterGroup>
                                <Marker
                                  position={[latitude, longitude]}
                                  icon={pin}
                                />
                              </MarkerClusterGroup>
                            </Map>
                          </td>
                        </tr>
                      )}

                      {/* Información de reporte */}
                      <tr className="unread">
                        <td>
                          <i className="fa-solid fa-address-card"></i>
                        </td>
                        <td className="td-block">
                          <h6 className="mb-1">
                            <Text tid="reportLastConfirmation" />
                          </h6>

                          <p className="m-0">
                            {isRecentConfirmation ? (
                              <>
                                {lastConfirmedDate.format("DD-MM-YYYY")}{" "}
                                <i className="fa fa-circle text-c-green f-10 m-r-15" />
                              </>
                            ) : (
                              <>
                                <Text tid="reportNoConfirmation" />
                                {": "}
                                <i className="fa fa-circle text-c-red f-10 m-r-15" />
                              </>
                            )}
                          </p>
                        </td>
                        <td></td>
                        <td>
                          <h6 className="mb-1 text-uppercase">
                            <Text tid="reportInfo" />
                          </h6>
                          <BailoDotSimpleReportsCard
                            mapdot={mapdot}
                            reportMessage={this.state.reportMessage}
                            handleChange={this.handleChange}
                            handleReportError={this.handleReportError}
                            handleReportConfirm={this.handleReportConfirm}
                          />
                        </td>
                      </tr>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
  layout: state.layout,
});

export default connect(mapStateToProps)(MapdotPopup);
