/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import React, { Component, Suspense } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";
import Loadable from "react-loadable";
import { ToastContainer } from "react-toastify";

import setAuthToken from "./store/utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./store/actions/authActions";
import store from "./store/store";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Loader from "./components/Loader";
import Aux from "./hoc/_Aux/Aux";
import ScrollToTop from "./components/ScrollToTop";

import PrivateRoute from "./components/PrivateRoute";
import AnonRoute from "./components/AnonRoute";

import Login from "./views/auth/Login";
import Signup from "./views/auth/Signup";
import Confirm from "./views/auth/Confirm";
import Gift from "./views/gift/Gift";

import PrivacyPolicy from "./views/footer/PrivacyPolicy";
import TermsOfUse from "./views/footer/TermsOfUse";
import LandingKB from "./views/landing/LandingKB";
import Blog from "./views/blog/Blog";
import SingleBlog from "./views/blog/SingleBlog";
import Landing from "./views/landing/Landing";
import NavPrices from "./views/prices/components/NavPrices";

import ErrorPage from "./views/error/ErrorPage";

import "./app.scss";

// TOKEN CHECKER
// Checks the local storage if there's a token, and if it still valid
if (localStorage.jwtToken) {
  // set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // decode token and get suer info and expiration
  const decoded = jwt_decode(localStorage.jwtToken);
  // set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // check for epired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // logout user
    store.dispatch(logoutUser());
  }
}

const Dashboard = Loadable({
  loader: () => import("./views/dashboard/Dashboard"),
  loading: Loader,
});

class App extends Component {
  componentDidMount() {
    console.log(
      "Hi there! Inspecting our code? Keen to know more about Bailo.app? Send us an email: bailo.app@gmail.com"
    );
  }

  //   import React, { Component } from 'react';
  // import './App.css';
  // import { BrowserRouter } from 'react-router-dom';
  // import Main from './components/main';
  // import REACTGA from 'react-ga';

  // REACTGA.initialize(process.env.REACT_APP_GA);
  // REACTGA.pageview(window.location.pathname + window.location.search);

  // class App extends Component {
  // 	render() {
  // 		return (
  // 				<BrowserRouter>
  // 					<div>
  // 						<Main />
  // 					</div>
  // 				</BrowserRouter>
  // 		);
  // 	}
  // }

  // export default App;

  // https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker

  render() {
    return (
      <>
        <ToastContainer autoClose={2500} />
        <Router>
          <Aux>
            <ScrollToTop>
              <Suspense fallback={<Loader />}>
                <Switch>
                  <AnonRoute exact path="/" component={Landing} />
                  <AnonRoute
                    exact
                    path="/terms-of-use"
                    component={TermsOfUse}
                  />
                  <AnonRoute
                    exact
                    path="/privacy-policy"
                    component={PrivacyPolicy}
                  />
                  <AnonRoute
                    exact
                    path="/knowledge-base"
                    component={LandingKB}
                  />
                  <AnonRoute exact path="/blog" component={Blog} />
                  <AnonRoute exact path="/blog/:id" component={SingleBlog} />
                  <AnonRoute exact path="/login" component={Login} />
                  <AnonRoute exact path="/signup" component={Signup} />
                  <AnonRoute exact path="/gift" component={Gift} />
                  <AnonRoute exact path="/prices" component={NavPrices} />
                  <AnonRoute exact path="/confirm/:id" component={Confirm} />
                  <PrivateRoute path="/dashboard" component={Dashboard} />
                  <Route path="*" component={ErrorPage} />
                </Switch>
              </Suspense>
            </ScrollToTop>
          </Aux>
        </Router>
      </>
    );
  }
}

export default App;
