/* eslint-disable class-methods-use-this */
import axios from "axios";

class ReportService {
  async getAllReports() {
    const { data: reports } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/reports`,
      {},
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return reports;
  }

  async getReportById(id) {
    const { data: report } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/api/reports/${id}`,
      {},
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return report;
  }

  async addReportError(message, date, mapdotId) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/api/reports/add-error`,
      {
        message,
        date,
        mapdotId,
      },
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  // Para usuarios autenticados
  async addReportErrorAllUsers(message, date, mapdotId) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/api/reports/add-error_AllUsers`,
      {
        message,
        date,
        mapdotId,
      },
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  // Para usuarios autenticados
  async addReportConfirm(date, mapdotId) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/api/reports/add-confirm`,
      {
        date,
        mapdotId,
      },
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }

  // Para usuarios NO autenticados
  async addReportConfirmAllUsers(date, mapdotId) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/api/reports/add-confirm_AllUsers`,
      {
        date,
        mapdotId,
      },
      {
        headers: {
          Authorization: `${localStorage.jwtToken}`,
        },
      }
    );
    return data;
  }
}

const reportService = new ReportService();

export default reportService;
