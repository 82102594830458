import React from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
// import { Card, Button, Modal, Col, Table } from "react-bootstrap";
import Text from "../../../components/Text";

const BailoDotAddressCard = ({ mapdot }) => {
  const { address, city, country, addressPlus, postalCode, categoryIdName } =
    mapdot;

  return (
    <>
      {/* Dirección */}
      {address &&
        categoryIdName !== "teacher" &&
        categoryIdName !== "musician" && (
          <tr className="unread">
            <td>
              <i className="fa-solid fa-location-dot" />
            </td>
            <td className="td-block">
              <h6 className="mb-1 text-uppercase">
                <Text tid="address" />
              </h6>
              <p className="m-0">{address}</p>
            </td>

            {/* Código Postal */}

            <td className="td-block">
              <h6 className="mb-1 text-uppercase">
                <Text tid="postalCode" />
              </h6>
              {!postalCode ? (
                <p className="m-0">n/a</p>
              ) : (
                <p className="m-0">{postalCode}</p>
              )}
            </td>

            {/* Ciudad */}

            <td className="td-block">
              <h6 className="mb-1 text-uppercase">
                <Text tid="city" />
              </h6>
              {!city ? (
                <p className="m-0">n/a - {country}</p>
              ) : (
                <p className="m-0">
                  {city} {country}
                </p>
              )}
            </td>
          </tr>
        )}
      {/* Dirección adicional */}
      <tr>
        <td>
          <i className="fa-solid fa-plus"></i>
        </td>
        <td className="td-block">
          <h6 className="mb-1 text-uppercase">
            <Text tid="addressPlus" />
          </h6>
          {!addressPlus ? (
            <p className="m-0">n/a </p>
          ) : (
            <p className="m-0">{addressPlus}</p>
          )}
        </td>
        <td className="td-block">
          <h6 className="mb-1 text-uppercase"> </h6>
        </td>
        <td className="td-block">
          <h6 className="mb-1 text-uppercase"> </h6>
        </td>
      </tr>
    </>
  );
};

BailoDotAddressCard.propTypes = {
  mapdot: PropTypes.object.isRequired,
  showInGoogle: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  lang: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
});

export default connect(mapStateToProps)(BailoDotAddressCard);
