/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";

import "../styles/scss/style.scss";

class PayPal extends Component {
  render() {
    return (
      <div>
        <div>{this.props.text}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
});

export default connect(mapStateToProps)(PayPal);
