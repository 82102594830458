/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
// import { connect } from "react-redux";
import { Carousel, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Text from "../../components/Text";
import "../../styles/css/_style.scss";
import logo from "../../styles/images/logo.png";
import backIphone from "../../styles/images/landing/iPhone11.png";
import cart1 from "../../styles/images/landing/troilo.png";
import cart2 from "../../styles/images/landing/diSarli.png";
import cart3 from "../../styles/images/landing/gardel.png";
import logo1 from "../../styles/images/landing/logoLinkedIn.png";
import logo2 from "../../styles/images/landing/logoFb.png";
import logo3 from "../../styles/images/landing/logoYt.png";
import logo4 from "../../styles/images/landing/logoInstagram.png";

class Prices extends Component {
  state = {
    followMapdots: [],
    myAttendMeetings: [],
    loading: true,
  };

  priceDesc = "#PriceDesc";

  //     price_desc.hover (() => ({
  //     speechBubble.css({
  //       "animation-name": "expand-bounce",
  //       "animation-duration": "0.25s"
  //     });
  //   },
  //   function() {
  //     speechBubble.css({
  //       "animation-name": "shrink",
  //       "animation-duration": "0.1s"
  //     });
  //   }
  // );

  changeBackground(e) {
    const speechBubble = "#SpeechBubble";

    e.target.style.background = "red";
    speechBubble.css({
      "animation-name": "expand-bounce",
      "animation-duration": "0.25s",
    });
  }

  handleMouseOut(e) {
    e.target.style.background = "white";
  }

  render() {
    return (
      <React.Fragment>
        <div className="wrapper">
          <div>
            <div className="main" id="main">
              <div className="features-section">
                <div className="f-left">
                  <div
                    className="left-content wow fadeInLeft"
                    data-wow-delay="0s"
                  >
                    <h2 data-wow-delay="0.1s">
                      We are available for custom work development
                    </h2>
                    <p className="wow fadeInLeft" data-wow-delay="0.2s">
                      <Text tid="priceDescription" />
                    </p>
                  </div>
                </div>
                <div
                  className="f-right"
                  style={{
                    backgroundImage: `url(${backIphone})`,
                    backgroundPosition: "50% 50%",
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div
                className="pricing-section no-color text-center"
                id="pricing"
              >
                <div className="container">
                  <Row>
                    <Col className="md-12 sm-12 ">
                      <div className="pricing-intro">
                        <h1>Pricing Table</h1>
                      </div>
                      <Row>
                        <Col className="sm-6">
                          <div
                            className="table-left wow fadeInUp"
                            data-wow-delay="0.4s"
                          >
                            <div className="icon">
                              <img src={cart2} alt="Icon" />
                            </div>
                            <div className="pricing-details">
                              <h2>Beginner Plan</h2>
                              <span>€60</span>
                              <p>
                                DI SARLI <br className="hidden-xs" />{" "}
                                <Text tid="paymentOptions" />.
                              </p>
                              <ul>
                                <li
                                  id="PriceDesc"
                                  onMouseOver={this.changeBackground}
                                  onMouseOut={this.handleMouseOut}
                                >
                                  {/* <p id="SpeechBubble"> */}
                                  <Text tid="bannerSize" />
                                  {" 340px x 175px"}
                                  {/* </p> */}
                                </li>
                                <li>
                                  <Text tid="bannerDesign" />
                                </li>
                                <li>
                                  <Text tid="linkToClientWeb" />
                                </li>
                                <li>
                                  <Text tid="facebookDisution" />
                                </li>
                                <li>
                                  <Text tid="InstagramDifusion" />
                                </li>
                                <li>
                                  <Text tid="mapDotClient" />
                                </li>
                                <li>
                                  <Text tid="mapdotDetailCli" />
                                </li>
                                <li>
                                  <Text tid="onMobileCli" />
                                </li>
                                <li>
                                  <Text tid="onWebCli" />
                                </li>
                                <li>
                                  <Text tid="goGoogleCli" />
                                </li>
                              </ul>
                              <button className="btn btn-primary btn-action btn-fill">
                                <Text tid="getPlan" />
                              </button>
                            </div>
                          </div>
                        </Col>
                        <Col className="sm-6 ">
                          <div
                            className="table-right wow fadeInUp"
                            data-wow-delay="0.6s"
                          >
                            <div className="icon">
                              <img src={cart1} alt="Icon" />
                            </div>
                            <div className="pricing-details">
                              <h2>Premium Plan</h2>
                              <span>€80</span>
                              <p>
                                TROILO <br className="hidden-xs" />{" "}
                                <Text tid="paymentOptions" />.
                              </p>
                              <ul>
                                <li>
                                  <Text tid="bannerSize" />
                                  {" 340px x 175px"}
                                </li>
                                <li>
                                  <Text tid="bannerDesign" />
                                </li>
                                <li>
                                  <Text tid="gifDesign" />
                                </li>
                                <li>
                                  <Text tid="bannerHighlight" />
                                </li>
                                <li>
                                  <Text tid="linkToClientWeb" />
                                </li>
                                <li>
                                  <Text tid="facebookDisution" />
                                </li>
                                <li>
                                  <Text tid="InstagramDifusion" />
                                </li>
                                <li>
                                  <Text tid="mapDotClient" />
                                </li>
                                <li>
                                  <Text tid="mapdotDetailCli" />
                                </li>
                                <li>
                                  <Text tid="onMobileCli" />
                                </li>
                                <li>
                                  <Text tid="goGoogleCli" />
                                </li>
                              </ul>

                              <Link to="/dashboard/gift" customTest={"true"}>
                                <button className="btn btn-primary btn-action btn-fill">
                                  <Text tid="getPlan" />
                                </button>
                              </Link>
                            </div>
                          </div>
                        </Col>
                        <Col className="sm-6 ">
                          <div
                            className="table-right wow fadeInUp"
                            data-wow-delay="0.6s"
                          >
                            <div className="icon">
                              <img src={cart3} alt="Icon" />
                            </div>
                            <div className="pricing-details">
                              <h2>Premium Plan</h2>
                              <span>€100</span>
                              <p>
                                GARDEL <br className="hidden-xs" />{" "}
                                <Text tid="paymentOptions" />.
                              </p>
                              <ul>
                                <li>
                                  <Text tid="bannerSize" />
                                  {" 340px x 175px"}
                                </li>
                                <li>
                                  <Text tid="bannerDesign" />
                                </li>
                                <li>
                                  <Text tid="gifDesign" />
                                </li>
                                <li>
                                  <Text tid="bannerHighlight" />
                                </li>
                                <li>
                                  <Text tid="linkToClientWeb" />
                                </li>
                                <li>
                                  <Text tid="facebookDisution" />
                                </li>
                                <li>
                                  <Text tid="InstagramDifusion" />
                                </li>
                                <li>
                                  <Text tid="notificacionesPush" />
                                </li>
                                <li>
                                  <Text tid="emailNotification" />
                                </li>
                                <li>
                                  <Text tid="mapDotClient" />
                                </li>
                                <li>
                                  <Text tid="mapdotDetailCli" />
                                </li>
                                <li>
                                  <Text tid="onMobileCli" />
                                </li>
                                <li>
                                  <Text tid="goGoogleCli" />
                                </li>
                              </ul>
                              <button className="btn btn-primary btn-action btn-fill">
                                <Text tid="getPlan" />
                              </button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="client-section">
                <div className="container text-center">
                  <Carousel
                    className="clients owl-carousel owl-theme"
                    interval={1000}
                  >
                    <Carousel.Item className="single">
                      <img src={logo1} alt="Brand 1" />
                    </Carousel.Item>
                    <Carousel.Item className="single">
                      <img src={logo2} alt="Brand 2" />
                    </Carousel.Item>
                    <Carousel.Item className="single">
                      <img src={logo3} alt="Brand 3" />
                    </Carousel.Item>
                    <Carousel.Item className="single">
                      <img src={logo4} alt="Brand 3" />
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
              <div className="cta-sub text-center no-color">
                <div className="container">
                  <h1 className="wow fadeInUp" data-wow-delay="0s">
                    New product notification subscription
                  </h1>
                  <p className="wow fadeInUp" data-wow-delay="0.2s">
                    We sent you daily mail about product updates / releases /
                    version change logs
                    <br className="hidden-xs" />
                    Please write accurate email address below.
                  </p>
                  <div className="form wow fadeInUp" data-wow-delay="0.3s">
                    <form className="subscribe-form wow zoomIn">
                      <input
                        className="mail"
                        type="email"
                        name="email"
                        placeholder="Email address"
                        autoComplete="off"
                      />
                      <button className="submit-button" type="button">
                        Subscribe
                      </button>
                    </form>
                    <div className="success-message" />
                    <div className="error-message" />
                  </div>
                </div>
              </div>
              <div className="footer">
                <div className="container">
                  <div className="col-md-12 text-center">
                    <img src={logo} alt="Datta Able Logo" />
                    <ul className="footer-menu">
                      <li>
                        <Link to="#">Site</Link>
                      </li>
                      <li>
                        <Link to="#">Support</Link>
                      </li>
                      <li>
                        <Link to="#">Terms</Link>
                      </li>
                      <li>
                        <Link to="#">Privacy</Link>
                      </li>
                    </ul>
                    <div className="footer-text">
                      <p>Copyright © 2019 - 2023 All Rights Reserved.</p>
                    </div>
                  </div>
                </div>
              </div>
              <a id="back-top" className="back-to-top page-scroll" href="#main">
                <i className="feather icon-arrow-up" />
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Prices;
